import React, { Component } from 'react';
import { Avatar, Badge, Row, Col, Button, Upload, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Field, FieldArray } from 'formik';
import { asyncConnect } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';
import * as yup from 'yup';
import { FileImageOutlined } from '@ant-design/icons';

import Error from 'components/common/Error';
import Spinner from 'components/common/Spinner';
import withRole from 'components/common/withRole';
import HeadBlock from 'components/common/cards/HeadBlock';
import ArrayButton from 'components/common/form/ArrayButton';
import Text from 'components/common/form/Text';
import Select from 'components/common/form/Select';
import SaleDatePicker from 'components/common/form/SaleDatePicker';
import PageForm from 'components/common/PageForm';
import ArrowBack from 'components/common/ArrowBack';
import { UNABLE_ADD_PHOTO_WITHOUT_CHECKIN } from 'constants/serverMessages';
import {
    PHOTO_REPORTS_TYPES,
    PHOTO_REPORTS_LABEL_MAP,
    PHOTO_REPORTS_LABEL_ADDITIONAL_MAP,
    PHOTO_REPORTS_LABEL_COMMENT_MAP,
    PHOTO_REPORTS_LABEL_ADDITIONAL_COMMENT_MAP
} from 'constants/photoReports';
import { FILE } from 'constants/urls';
import { getFileUrl } from 'utils/file';
import { getActionUrl } from 'utils/http';
import { getJWTToken } from 'utils/token';
import { checkError } from 'utils/error';
import {
    getCurrentCheckin,
    getPhotoReport,
    postPhotoReport,
    putPhotoReport,
    postFile,
} from 'actions/asyncActions';
import { fullAutoOptions, formAutoOptions } from 'constants/asyncClient';
import { openPreviewModal } from 'actions/modalActions';

@withRole
@withRouter
@asyncConnect({
    getPhotoReport: getPhotoReport
        .withPayload(path(['match', 'params', 'id']))
        .withOptions(fullAutoOptions),
    getCurrentCheckin: getCurrentCheckin
        .withOptions(fullAutoOptions),
    postAction: postPhotoReport.withOptions(formAutoOptions),
    putAction: putPhotoReport.withOptions(formAutoOptions),
    postFile: postFile
}, null, { openPreviewModal })
export default class PhotoReportsForm extends Component {
    constructor(props) {
        super();
    }

    onSubmitSuccess = () => {
        message.success('Отчет успешно обновлен.');

        this.props.history.push('/checkin');
    }

    onChangeUpload = push => info => {
        const { status, name } = info.file;
        switch (status) {
            case 'done':
                const { id } = info.file.response;
                message.success(`${name} файл успешно загружен.`);
                push({ id });
                break;
            case 'error':
                message.error(`Ошибка загрузки файла ${name}.`);
                break;
            default:
        }
    }

    renderPhotoField = (name, label, disabled) => (
        <FieldArray name={name} render={({ form, push, remove }) => {
            return (
                <div>
                    <div className=''>
                        <Row>
                            <b>{label}</b>
                        </Row>
                        <Row>
                            { (form.values[name] || []).map((photo, index) => (
                                <Col span={8}>
                                    <Badge
                                        style={{ backgroundColor: '#304056', padding: 7, borderRadius: '50%' }}
                                        count={!disabled ? <CloseOutlined style={{ color: '#fff' }} /> : 0}
                                        onClick={() => !disabled && remove(index)}
                                    >
                                        <Avatar
                                            shape="square"
                                            size={{ xs: 110 }}
                                            src={getFileUrl(photo.id)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.openPreviewModal(getFileUrl(photo.id));
                                            }}
                                        />
                                    </Badge>
                                </Col>
                            ))}
                            { !disabled && (form.values[name].length < 10) && (
                                <Col span={8}>
                                    <Field
                                        name={name}
                                        component={ArrayButton}
                                        button={(
                                            <Upload
                                                key={form.values[name] ? form.values[name].length : '0'}
                                                name={`file`}
                                                onChange={this.onChangeUpload(push)}
                                                multiple={false}
                                                action={getActionUrl(FILE)}
                                                accept='image/*'
                                                capture='camera'
                                                headers={{ Authorization : getJWTToken()}}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    className='primary-dashed photo-button'
                                                    type='dashed'
                                                    block
                                                >
                                                    <br />
                                                    <FileImageOutlined style={{ fontSize: '30px' }} /><br />
                                                    Сделать фото<br />
                                                    <small>До 10 штук</small>
                                                </Button>
                                            </Upload>
                                        )}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            );
        }} />
    )

    renderForm = formProps => {
        const { isPromoter, getPhotoReport: { data: report }, getCurrentCheckin: { data } } = this.props;
        const disabled = !!this.getId() ? report.date !== data.date : false;
        return (
            <div>
                <Field
                    name='date'
                    component={SaleDatePicker}
                    disabled={isPromoter}
                />
                <Field
                    name='type'
                    component={Select}
                    options={PHOTO_REPORTS_TYPES}
                    placeholder='Выберите отчет'
                    allowClear={true}
                    disabled={isPromoter && (path(['match', 'params', 'type'], this.props) || this.getId())}
                />
                {formProps.values.type ? (
                    <div>
                        {this.renderPhotoField('photos', PHOTO_REPORTS_LABEL_MAP[formProps.values.type], disabled)}
                        <Field
                            label={PHOTO_REPORTS_LABEL_COMMENT_MAP[formProps.values.type]}
                            name='comment'
                            component={Text}
                            disabled={disabled}
                        />
                        {this.renderPhotoField('additionalPhotos', PHOTO_REPORTS_LABEL_ADDITIONAL_MAP[formProps.values.type], disabled)}
                        <Field
                            label={PHOTO_REPORTS_LABEL_ADDITIONAL_COMMENT_MAP[formProps.values.type]}
                            name='additionalComment'
                            component={Text}
                            disabled={disabled}
                        />
                    </div>
                ) : null}
                { !disabled && (
                    <Button
                        className='pull-right'
                        type='primary'
                        htmlType='submit'
                        disabled={formProps.isSubmitting}
                        onClick={formProps.handleSubmit}
                    >
                        Сохранить
                    </Button>
                )}
                <div className='pull-clear' />
            </div>
        );
    };

    getValidationSchema() {
        const { isSupervisor } = this.props;

        return yup.object().shape({
            promoter: isSupervisor ? yup.string().nullable().required() : yup.string().nullable(),
            date: yup.date().nullable().required(),
            type: yup.string().nullable().required(),
            photo: yup.array().of(
                yup.object().shape({
                    id: yup.string().nullable().required(),
                })
            ).max(10, 'Максимум 10 фото'),
        });
    }

    getId() {
        return path(['match', 'params', 'id'], this.props);
    }

    getInitialValues(checkin, report, isEdit) {
        return isEdit ? {
            ...report,
            photos: (report.photos || []).map(id => ({ id })),
            additionalPhotos: (report.additionalPhotos || []).map(id => ({ id })),
        } : {
            type: path(['match', 'params', 'type'], this.props),
            date: checkin.date,
            promoter: checkin.promoter,
            shop: checkin.shop,
            photos: [],
            additionalPhotos: [],
        };
    }

    render() {
        const { getPhotoReport: { data: report, meta: reportMeta }, getCurrentCheckin: { data, meta }, postAction, putAction } = this.props;
        const active = data && data.status !== 'finished';
        const isEdit = !!this.getId();

        return (
            <div className='sales-form'>
                <ArrowBack />
                <HeadBlock title='Заполнить фотоотчет' hideBack />
                { reportMeta.pending || meta.pending ? (
                    <Spinner />
                ) : reportMeta.error || meta.error ? (
                    <Error meta={reportMeta.error || meta.error} />
                ) : data.id && active ? (
                    <PageForm
                        initialValues={this.getInitialValues(data, report, isEdit)}
                        validationSchema={this.getValidationSchema()}
                        formAction={isEdit ? putAction : postAction}
                        renderForm={this.renderForm}
                        onSubmitSuccess={this.onSubmitSuccess}
                        onSubmitFail={checkError('date', UNABLE_ADD_PHOTO_WITHOUT_CHECKIN)}
                    />
                ) : (
                    'Нет активного чекина'
                )}
            </div>
        );
    }
}
