import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { getPhotoReports } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PHOTO_REPORTS_TYPES } from 'constants/photoReports';
import DateField from 'components/common/cards/Date';

const TaskWrapper = styled.div`
    padding: 16px 8px;
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #F0F2F5;
        opacity: ${({ complete }) => complete ? .6 : 0};
    }
`;

const TaskPriority = styled.div`
    min-width: 6px;
    margin-right: 10px;
    height: 100%;

    &:before {
        content: '';
        border-radius: 40px;
        width: 6px;
        position: absolute;
        top: 8px;
        bottom: 8px;
        background: #F41E59
    }
`;

const TaskTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 5px;
    p {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        margin-bottom: 0;
    }
`;

const TaskDate = styled.div`
    font-size: 13px;
    color: ${({ highlight }) => highlight ? '#FFFF00' : '#8F8F8F'};
`;

const TaskDraft = styled.div`
    right: 30px;
    position: absolute;
    font-size: 13px;
    color: #FFFF00;
`;

@asyncConnect({
    getPhotoReports: getPhotoReports
        .withPayload(({ checkin }) => ({
            q: {
                shop: checkin.shop,
                date: checkin.date,
            },
            limit: 0
        }))
        .withOptions(fullAutoOptions)
})
export default class CheckinPhotoReports extends Component {
    render() {
        const { getPhotoReports: { data, meta }, checkin } = this.props;

        return <div className='mt15'>
            <h2 className='title'>Фотоотчеты</h2>
            {meta.pending ? <Spin /> : (
                <div>
                    { (PHOTO_REPORTS_TYPES).map(report => {
                        const hasResult = data.items && data.items.length && data.items.find(item => item.type === report.id && item.photos && item.photos.length && item.additionalPhotos && item.additionalPhotos.length)
                        const hasDraft = data.items && data.items.length && data.items.find(item => item.type === report.id);

                        return <Link key={report.id} to={hasDraft ? `/photo/edit/${hasDraft.id}` : `/photo/add/${report.id}`}>
                            <TaskWrapper complete={hasResult}>
                                <TaskPriority />
                                <div>
                                    <TaskTitle className='task-table-text' style={hasResult ? { textDecoration: 'line-through' } : null}>
                                        {report.name}
                                    </TaskTitle>
                                    <TaskDate highlight={hasDraft}><ClockCircleOutlined /> <DateField hideIcon date={checkin.date} /></TaskDate>
                                </div>
                                { !!hasDraft && <TaskDraft><EditOutlined /></TaskDraft>}
                            </TaskWrapper>
                        </Link>
                    })}
                </div>
            )}
        </div>;
    }
}
