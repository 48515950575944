import React, { Component } from 'react';
import { Carousel, Modal, Row, Col, Button } from 'antd';
import { path } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import Spinner from 'components/common/Spinner';
import Error from 'components/common/Error';
import Date from 'components/common/cards/Date';
import PersonName from 'components/common/cards/person/PersonName';
import { getPhotoReport, fullAutoOptions } from 'actions/asyncActions';
import { openPreviewModal } from 'actions/modalActions';
import {
    PHOTO_REPORTS_MAP,
    PHOTO_REPORTS_LABEL_MAP,
    PHOTO_REPORTS_LABEL_ADDITIONAL_MAP,
    PHOTO_REPORTS_LABEL_COMMENT_MAP,
    PHOTO_REPORTS_LABEL_ADDITIONAL_COMMENT_MAP
} from 'constants/photoReports';
import { getFileUrl } from 'utils/file';

@asyncConnect({
    getPhotoReport: getPhotoReport
        .withPayload(path(['params', 'id']))
        .withOptions(fullAutoOptions),
}, null, { openPreviewModal })
export default class PhotoReportModal extends Component {
    render() {
        const { modal, params, getPhotoReport: { data, meta } } = this.props;

        return (
            <Modal {...modal} title={<span><Date date={params.date} /> {`${params._embedded.network.name} ${params._embedded.shop.name} ${PHOTO_REPORTS_MAP[params.type]} `} <PersonName person={params._embedded.promoter} /></span>} footer={null} className='preview-modal'>
                <div>
                    {meta.pending ? (
                        <Spinner />
                    ) : meta.error ? (
                        <Error meta={meta} />
                    ) : (
                        <div>
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center' }}><b>{PHOTO_REPORTS_LABEL_MAP[data.type]}</b></Col>
                                <Col sm={12} style={{ textAlign: 'center' }}><b>{PHOTO_REPORTS_LABEL_ADDITIONAL_MAP[data.type]}</b></Col>
                            </Row>
                            <Row>
                                { data.photos && data.photos.length ? (
                                    <Col sm={12}>
                                        <Carousel ref={node => this.photosSlider = node}>
                                            {(data.photos || []).map(id => (
                                                <div><img src={getFileUrl(id)} alt='' /></div>
                                            ))}
                                        </Carousel>
                                        <div style={{ textAlign: 'center', padding: 8 }}>
                                            <Button.Group>
                                                <Button onClick={() => this.photosSlider.prev()}><ArrowLeftOutlined /></Button>
                                                <Button onClick={() => this.photosSlider.next()}><ArrowRightOutlined /></Button>
                                            </Button.Group>
                                        </div>
                                    </Col>
                                ) : <Col sm={12} style={{ textAlign: 'center' }}>Нет фото</Col>}
                                { data.additionalPhotos && data.additionalPhotos.length ? (
                                    <Col sm={12}>
                                        <Carousel ref={node => this.photosAdditionalSlider = node}>
                                            {(data.additionalPhotos || []).map(id => (
                                                <div><img src={getFileUrl(id)} alt='' /></div>
                                            ))}
                                        </Carousel>
                                        <div style={{ textAlign: 'center', padding: 8 }}>
                                            <Button.Group>
                                                <Button onClick={() => this.photosAdditionalSlider.prev()}><ArrowLeftOutlined /></Button>
                                                <Button onClick={() => this.photosAdditionalSlider.next()}><ArrowRightOutlined /></Button>
                                            </Button.Group>
                                        </div>
                                    </Col>
                                ) : <Col sm={12} style={{ textAlign: 'center' }}>Нет фото</Col>}
                            </Row>
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center' }}><b>{PHOTO_REPORTS_LABEL_COMMENT_MAP[data.type]}</b></Col>
                                <Col sm={12} style={{ textAlign: 'center' }}><b>{PHOTO_REPORTS_LABEL_ADDITIONAL_COMMENT_MAP[data.type]}</b></Col>
                            </Row>
                            <Row>
                                <Col sm={12}>{data.comment}</Col>
                                <Col sm={12}>{data.additionalComment}</Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
};

